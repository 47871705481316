import { BaseRepository } from "@/api/baseRepository";
import { CookiesStorage } from "@/core/storage/drivers/cookiesStorage";
import { replaceEnvPath } from "@/shared/utils";

const BACKEND_ENDPOINTS = {
  AUTH: { url: '/v1/auth' },
  VALIDATE: { url: '/v1/validate' },
  TOKEN: { url: '/v1/token' },
  REISSUE_TOKEN: { url: '/v1/reissue_token' },
  LOGOUT: { url: '/v1/me/logout' },
  CORP_SSO_URL: { url: '/v1/corp_sso_url' },
  CORP_AUTH: { url: '/v1/corp_auth' },
};

// Authenticate user by email/password or by token
export function auth(data = {}) {
  const path = BACKEND_ENDPOINTS.AUTH.url;
  return BaseRepository.post(path, data, {
    useOpid: false,
    useIdmp: false,
  });
}

// Validates opaque token, validates access to the component and return dict versions
export function validate(data = {}) {
  const path = BACKEND_ENDPOINTS.VALIDATE.url
  return BaseRepository.post(path, data, {
    useIdmp: false,
  });
}

// Logout current user
export function logout() {
  const logoutOpid = CookiesStorage.get('OPID');

  if (!logoutOpid) {
    return Promise.resolve();
  }

  const path = BACKEND_ENDPOINTS.LOGOUT.url;
  return BaseRepository.get(path, {
    useIdmp: false,
    opid: logoutOpid,
    checkErrors: false,
  });
}

// Send email by token type
export function token(data = {}, checkErrors = true) {
  const path = BACKEND_ENDPOINTS.TOKEN.url
  return BaseRepository.post(path, data, {
    useIdmp: false,
    checkErrors,
  });
}

export function reissueToken(payload = {}, checkErrors = true) {
  const {
    client_id,
    opid,
  } = payload;
  const path = BACKEND_ENDPOINTS.REISSUE_TOKEN.url
  return BaseRepository.post(path, {
    client_id,
  }, {
    useIdmp: false,
    checkErrors,
    opid,
  });
}

export function getCorpSSOUrl(params = {}) {
  const path = BACKEND_ENDPOINTS.CORP_SSO_URL.url;
  return BaseRepository.get(path, {
    useIdmp: false,
    params,
  });
}

export function corpAuth(data = {}) {
  const path = BACKEND_ENDPOINTS.CORP_AUTH.url;
  return BaseRepository.post(path, data, {
    useIdmp: false,
  });
}

export default {
  auth,
  validate,
  logout,
  token,
};
