import { BaseRepository } from '@/api/baseRepository';
import { replaceEnvPath } from '@/shared/utils';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import { API_FILTER_TYPES } from "@/config/enums";

export const BACKEND_ENDPOINTS = {
  // Clients
  CLIENTS: { url: '/v2/clients' },
  CLIENT: { url: '/v2/clients/{client_id}' },
  CLIENTS_QUERY: {
    url: '/v2/clients/query',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
      name: { type: API_FILTER_TYPES.Q_STRING },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      source_type_id: { type: API_FILTER_TYPES.IN_INT },
      source_id: { type: API_FILTER_TYPES.IN_INT },
      'smart_settings.country_id': { type: API_FILTER_TYPES.IN_INT },
    },
  },
  // Client daughters
  CLIENT_DAUGHTERS: {
    url: '/v2/client_daughters',
    filter: {
      client_id: { type: API_FILTER_TYPES.IN_INT },
      daughter_id: { type: API_FILTER_TYPES.IN_INT },
    },
  },
  CLIENT_DAUGHTER: { url: '/v2/client_daughters/{client_daughter_id}' },
  CLIENT_COMPANY_FOLDERS: {
    url: '/v2/smart_client_company_folders',
    filter: {
      id: { type: API_FILTER_TYPES.IN_INT },
      client_id: { type: API_FILTER_TYPES.IN_INT },
      client_company_folder_type_id: { type: API_FILTER_TYPES.IN_INT },
      created_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
    },
  },
  CLIENT_COMPANY_FOLDER: { url: '/v2/smart_client_company_folders/{folder_id}' },
  CLIENT_USERS_QUERY: {
    url: '/v2/client_users/query',
    filter: {
      'user.id': { type: API_FILTER_TYPES.IN_INT },
      'user.source_type_id': { type: API_FILTER_TYPES.EQ_INT },
      'user.source_id': { type: API_FILTER_TYPES.EQ_STRING },
      'user.email': { type: API_FILTER_TYPES.EQ_STRING },
      'user.created_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'user.modified_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'user.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'user.is_dummy': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'client.id': { type: API_FILTER_TYPES.IN_INT },
      'client.name': { type: API_FILTER_TYPES.Q_STRING },
      'smart_settings.license_id': { type: API_FILTER_TYPES.EQ_INT },
      'smart_settings.status': { type: API_FILTER_TYPES.EQ_INT },
      'smart_settings.invite_status': { type: API_FILTER_TYPES.EQ_INT },
      'smart_settings.invited_at': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'subscription.end_date': { type: API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'user_profile.is_suspended': { type: API_FILTER_TYPES.EQ_BOOLEAN },
      'subscription.id': { type: API_FILTER_TYPES.IN_INT },
    },
  },
  CLIENT_USER: { url: '/v2/client_users/{client_id}/{user_id}' },
  CLIENT_USER_ACTIVATE: { url: '/v2/client_users/{client_id}/{user_id}/smart_activate' },
};

// Get clients
export function getClients(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CLIENTS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, {
    subscriptions: 0,
    ...data,
  });
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

// Add new client
export function postClient(data = {}) {
  const path = BACKEND_ENDPOINTS.CLIENTS.url;
  return BaseRepository.post(path, data);
}

// Update Client
export function putClient(client_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT.url, { client_id });
  return BaseRepository.put(path, data);
}

export function patchClient(client_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT.url, { client_id });
  return BaseRepository.patch(path, data);
}

// Returns client data
export function getClient(client_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT;
  const path = replaceEnvPath(endpoint.url, { client_id });
  const params = convertQueryToGetParams(endpoint, {
    id_type: 'sharp',
    subscriptions: 0,
    ...data,
  });
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export function deleteClient(client_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT.url, { client_id });
  return BaseRepository.delete(path);
}

// Returns client info data
export function getClientInfo(client_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT_INFO.url, { client_id });
  return BaseRepository.get(path, {
    useIdmp: false,
  });
}

// Update client data
export function updateClients(client_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT.url, { client_id });
  return BaseRepository.patch(path, data);
}

// Client daughters
export function addClientDaughter(client_id, daughter_id) {
  const path = BACKEND_ENDPOINTS.CLIENT_DAUGHTERS.url;
  return BaseRepository.post(path, { client_id, daughter_id });
}

export function getClientDaughters(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT_DAUGHTERS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function getClientDaughter(client_daughter_id, data = null) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT_DAUGHTER;
  const path = replaceEnvPath(endpoint.url, { client_daughter_id });
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export function deleteClientDaughter(client_daughter_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT_DAUGHTER.url, { client_daughter_id });
  return BaseRepository.delete(path);
}

export function getClientCompanyFolders(data = null) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT_COMPANY_FOLDERS;
  const params = convertQueryToGetParams(endpoint, data);
  return BaseRepository.get(endpoint.url, {
    params,
    useIdmp: false,
  });
}

export function postClientCompanyFolder(data = {}) {
  const path = BACKEND_ENDPOINTS.CLIENT_COMPANY_FOLDERS.url;
  return BaseRepository.post(path, data);
}

export function deleteClientCompanyFolder(folder_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT_COMPANY_FOLDER.url, { folder_id });
  return BaseRepository.delete(path);
}

export function getClientUsers(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT_USERS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, {
    subscriptions: 0,
    ...data,
  });

  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getClientUser(client_id, user_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.CLIENT_USER;
  const path = replaceEnvPath(endpoint.url, { client_id, user_id });
  const params = convertQueryToGetParams(endpoint, {
    subscriptions: 0,
    ...data,
  });
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export async function patchClientUser(payload = {}) {
  const {
    clientId,
    userId,
    data = {},
    params = {},
  } = payload;
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT_USER.url, {
    client_id: clientId,
    user_id: userId,
  });

  return BaseRepository.patch(path, data, {
    params,
  });
}

export function activateClientUser(client_id, user_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.CLIENT_USER_ACTIVATE.url, {
    client_id,
    user_id,
  });
  return BaseRepository.put(path);
}
