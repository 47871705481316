import { BaseRepository } from '@/api/baseRepository';
import { convertQueryToGetParams, convertQueryToJsonRpc } from '@/shared/api';
import enums from "@/config/enums";
import { replaceEnvPath } from "@/shared/utils";

const BACKEND_ENDPOINTS = {
  SUBSCRIPTIONS: { url: '/v2/subscriptions' },
  SUBSCRIPTIONS_QUERY: {
    url: '/v2/subscriptions/query',
    filter: {
      id: { type: enums.API_FILTER_TYPES.IN_INT },
      order_id: { type: enums.API_FILTER_TYPES.EQ_STRING },
      start_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      end_date: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      end_date_info: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      is_suspended: { type: enums.API_FILTER_TYPES.EQ_BOOLEAN },
      created_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      modified_at: { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      subscription_type_id: { type: enums.API_FILTER_TYPES.IN_INT },
      'smart_settings.country_id': { type: enums.API_FILTER_TYPES.IN_INT },
      'smart_settings.project_history_start_date': { type: enums.API_FILTER_TYPES.GTE_LTE_DATE_TIME },
      'smart_settings.support_phone': { type: enums.API_FILTER_TYPES.Q_STRING },
      'smart_settings.support_email': { type: enums.API_FILTER_TYPES.Q_STRING },
      'smart_settings.support_name': { type: enums.API_FILTER_TYPES.Q_STRING },
      'smart_settings.modules': { type: enums.API_FILTER_TYPES.IN_INT },
      'smart_settings.fixed_modules': { type: enums.API_FILTER_TYPES.IN_INT },
      'smart_settings.fixed_addon_ids': { type: enums.API_FILTER_TYPES.IN_INT },
      'smart_settings.addon_ids': { type: enums.API_FILTER_TYPES.IN_INT },
      client_id: { type: enums.API_FILTER_TYPES.IN_INT },
      source_id: { type: enums.API_FILTER_TYPES.IN_STRING },
      source_type_id: { type: enums.API_FILTER_TYPES.IN_STRING },
    },
  },
  SUBSCRIPTION: { url: '/v2/subscriptions/{subscription_id}' },
};

export function getSubscriptions(data = {}) {
  const endpoint = BACKEND_ENDPOINTS.SUBSCRIPTIONS_QUERY;
  const query = convertQueryToJsonRpc(endpoint, {
    id_type: 'sharp',
    smart_selections: 0,
    ...data,
  });
  return BaseRepository.post(endpoint.url, query, {
    useIdmp: false,
    query: true,
  });
}

export function getSubscription(subscription_id, data = {}) {
  const endpoint = BACKEND_ENDPOINTS.SUBSCRIPTION;
  const path = replaceEnvPath(endpoint.url, { subscription_id });
  const params = convertQueryToGetParams(endpoint, {
    id_type: 'sharp',
    smart_selections: 0,
    ...data,
  });
  return BaseRepository.get(path, {
    params,
    useIdmp: false,
  });
}

export function deleteSubscription(subscription_id) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SUBSCRIPTION.url, { subscription_id });
  return BaseRepository.delete(path);
}

export function postSubscription(data = {}) {
  const path = BACKEND_ENDPOINTS.SUBSCRIPTIONS.url;
  return BaseRepository.post(path, data);
}

export function patchSubscription(subscription_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SUBSCRIPTION.url, { subscription_id });
  return BaseRepository.patch(path, data);
}

export function putSubscription(subscription_id, data = {}) {
  const path = replaceEnvPath(BACKEND_ENDPOINTS.SUBSCRIPTION.url, { subscription_id });
  return BaseRepository.put(path, data);
}
